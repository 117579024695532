export default {
    data(){
        return{
            savedConditionsCopy: [],
        }
    },
    mounted(){
        this.savedConditionsCopy = this.savedConditions
    },
    methods: {
        onConditionChange(e) {
            this.newConditionId = e.id
        }

        // NEED TO DELETE IF PAGINATION WILL BE REMOVED
        // onIntersect() {
        //     console.log("loading data");
        // },
    }
  }
  