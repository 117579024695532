import {multilangService} from "@/services/multilang.service";

export const multilang = {
  namespaced: true,

  state: {
    texts: [],
    totalResults: 0,
    emptyInfo: [],
  },

  getters: {
    texts: state => state.texts,
    totalResults: state => state.totalResults,
    emptyInfo: state => state.emptyInfo.emptyInfo,
  },

  mutations: {

    setTexts(state, texts) {
      state.totalResults = texts.total;
      state.texts = texts.texts;
    },

    setTextsOnScroll(state, data) {
      if (data.isReset) {
        state.texts = data.texts.texts;
      } else {
        state.texts = [...state.texts, ...data.texts.texts];
      }
      state.totalResults = data.texts.total;
    },

    setEmptyInfo(state, emptyInfo) {
      state.emptyInfo = emptyInfo;
    },

    setPrevText(state, temp) {
      console.log('temp - ', temp);
      state.texts = state.texts.map(text => {
        if (text.textId === temp.textId) {
          text = temp;
        }
        return text;
      })
      console.log('texts - ', state.texts);
    }
  },

  actions: {

    // get texts with params
    async getTexts({commit}, params) {
      const texts = await multilangService.getTexts(params);
      commit("setTexts", texts.response);
    },

    // get texts on scroll
    async getTextsOnScroll({commit}, params) {
      const texts = await multilangService.getTexts(params);
      commit("setTextsOnScroll", {texts: texts.response, isReset: params.isReset});
    },

    // get emptyInfo
    async getEmptyInfo({commit}) {
      const emptyInfo = await multilangService.getEmptyInfo();
      commit('setEmptyInfo', emptyInfo.response);
    },

    // download excel from server
    async downloadExcel({dispatch}, params) {
      const response = await multilangService.downloadExcel(params);
      if (response.status === 1) {
        const status = await dispatch("checkStatus", 'download');
        return `${process.env.VUE_APP_STATIC_URL}${status.response.location}`;
      }
    },

    // upload excel on server
    async uploadExcel({dispatch}, files) {
      const response = await multilangService.uploadExcel(files);
      if (response.status === 1) {
        const status = await dispatch("checkStatus", 'upload');
        if (status.response?.status !== "error") {
          return status;
        }
      }
    },

    // check status of excel requests
    async checkStatus({commit}, method) {
      console.log("status commit - ", commit);
      const status = new Promise((res, rej) => {
        let downloadStatus = null;
        const interval = setInterval(async () => {
          downloadStatus = method === 'download' ? await multilangService.checkDownloadStatus() : await multilangService.checkUploadStatus();
          if (downloadStatus.status === 1) {
            if (downloadStatus.response?.status === 'completed' || downloadStatus.response?.location) {
              res(downloadStatus);
              clearInterval(interval);
            }
            if (downloadStatus.response?.status === "error") {
              rej(downloadStatus);
              clearInterval(interval);
            }
          }
        }, 1000);
      });
      return await status;
    },

    async editText({commit}, data) {
      try {
        const response = await multilangService.editText(data.translation);
        if (response?.status !== 1) {
          commit("setPrevText", data.tempEdit);
        }
        console.log('edit response - ', response);
        return response;
      } catch (e) {
        commit("setPrevText", data.tempEdit);
        console.log("error on edit text - ", e);
      }
    }
  }
}