export default {
  data() {
    return {
      selectedPreset: null,
    }
  },
  computed: {
    presets() {
      return this.$store.getters["node/presets"];
    },
    nodeId() {
      return this.$store.getters["node/nodeId"];
    },
    nodeType() {
      return this.$store.getters["node/nodeType"];
    },
  },
  created() {
    if (localStorage.getItem(`preset-for-node-${this.nodeId}`)) {
      this.presets.forEach((preset) => {
        if (String(preset.id) === localStorage.getItem(`preset-for-node-${this.nodeId}`)) {
          this.selectedPreset = preset.id;
        }
      })
    }
    this.$forceUpdate();
  },
  methods: {
    async getNodePreset(preset) {
      let connections = this.$store.getters["node/connections"]
      this.$store.commit('node/setNodeEditLoading', true);
      localStorage.setItem(`preset-for-node-${this.nodeId}`, preset);
      await this.$store.dispatch("node/setSettings", {id: this.nodeId, preset, type: this.nodeType, connections})
      this.$store.commit('node/setNodeEditLoading', false);
    }
  }
}