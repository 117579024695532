<template>
  <v-app>
    <div class="some-div" :style="{'background': $colorAdmin}"></div>
    <NavComponent
      v-if="isLoggedIn && !loading"
      :mini-sidebar="miniSidebar"
    />
    <NavBusinessProcess
      v-if="isLoggedIn && !loading"
      v-show="$route.name === 'business-process'"
    />
    <HeaderComponent
      v-if="isLoggedIn"
      :mini-sidebar="miniSidebar"
      @click="miniSidebar = !miniSidebar"
    />
    <AlertListComponent :alerts="alerts" />
    <v-main class="main-content">
      <v-container fluid>
        <keep-alive exclude="ScreenEdit">
          <router-view></router-view>
        </keep-alive>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavComponent from "@/components/NavComponent";
import HeaderComponent from "@/components/HeaderComponent";
import NavBusinessProcess from "@/components/business-process/NavBusinessProcess";
import AlertListComponent from "./components/AlertListComponent";

export default {
  name: "App",
  components: {
    HeaderComponent,
    NavComponent,
    NavBusinessProcess,
    AlertListComponent
  },
  data: () => ({
    loading: false,
    miniSidebar: true
  }),
  methods: {
    async getInit () {
      if (this.isLoggedIn) {
        await this.$store.dispatch("app/getInitProps");
      }
    }
  },
  computed: {
    alerts () {
      return this.$store.getters["alert/alerts"];
    },
    isLoggedIn() {
      return this.$store.getters["authentication/isLoggedIn"];
    },
    project () {
      return this.$store.getters["project/project"];
    }
  },
  async created () {
    await this.getInit();
  },
  watch: {
    $route () {
      this.$store.dispatch("alert/clear");
    }
  }
};
</script>

<style scoped lang="scss">
.some-div {
  position: fixed;
  height: 300px;
  width: 100%;
  z-index: 0;
}
</style>
