import {projectService} from "@/services/project.service";

export const app = {
  namespaced: true,
  state: {
    files: null,
    textStyles: [
        'font-text-vernum', 'font-text-apprsum', 'font-text-availsum',
        'font-text-ctabtn', 'font-text-btn', 'font-text-title',
        'font-text-large', 'font-text-body', 'font-text-small',
        'font-text-interlinear', 'font-text-navbar', 'font-text-h1',
        'font-text-h2', 'font-text-h3', 'font-text-h4', 'font-text-h5',
        'font-text-h6'
    ],
    colorTextSwatches: [
        ['#FFFFFF'],
        ['#0E1A22'],
        ['#242424'],
        ['#B51ACE'],
        ['#E127FF'],
        ['#43B02A'],
        ['#5A717C'],
        ['#707070'],
        ['#A0A0A0'],
        ['#E32A5F'],
    ],
  },
  getters: {
    files: state => state.files,
    textStyles: state => state.textStyles,
    colorTextSwatches: state => state.colorTextSwatches,
  },
  mutations: {
    setFiles(state, files) {
      state.files = files;
    },
    addFiles(state, files) {
      files.forEach(file => {
        state.files.push(file);
      })
    },
  },
  actions: {
    async getInitProps({dispatch}) {
      await dispatch('node/setFieldProps', null, {root: true});
      await dispatch('project/getProjects', null, {root: true});
      await dispatch('project/getProject', 12, {root: true});
      await dispatch('setFiles');
    },
    async setFiles({commit}) {
      await projectService.getFiles()
        .then((files) => {
          commit('setFiles', files.response.images);
        })
        .catch(() => {
          commit('setFiles', null);
        })
    },
    async addFiles({commit}, files) {
      await projectService.addFiles(files)
        .then(resp => {
          commit("addFiles", resp.response.file);
        })
        .catch(() => {
          console.log('error');
        })
    }
  }
}