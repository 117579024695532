<template>
  <div class="btn-toggle" :class="{'disabled': disabled}">
    <button 
      @click="switchParam(param1)" 
      class="btn-toggle__type" 
      :class="{'active': internalType, 'disabled': disabled}"
      :disabled="disabled"
    >{{ param1 }}</button>
    <button 
      @click="switchParam(param2)" 
      class="btn-toggle__type" 
      :class="{'active': !internalType, 'disabled': disabled}"
      :disabled="disabled"
    >{{ param2 }}</button>
  </div>
</template>

<script>
export default {
  name: "SwitcherComp",
  props: {
    param1: {
      type: String,
      default: 'param1'
    },
    param2: {
      type: String,
      default: 'param2'
    },
    selectedParam: {
      type: String 
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalType(){
        return this.selectedParam === this.param1
      },
  },
  methods: {
    /** toggle button
     * @param {String} name button 
     */
    switchParam(type){ 
      type === this.param1
        ? this.$emit('switchParam', this.param1)
        : this.$emit('switchParam', this.param2)
    }
  }
}
</script>

<style scoped lang="scss">
.btn-toggle{
  border-radius: 12px;
  background: #F6F9FC;
  padding: 6px;
  gap: 4px;
  display: flex;

  &.disabled{
    background: #eaeaea;
  }

  &__type {
    padding: 4px;
    width: 50%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700; 
    color: #172B4D;

    &.active{
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
      background: #FFF;
    }

    &.disabled{
      background: #eaeaea;
    }
  }
}
</style>
