export default {
    data(){
      return{
        locationPopup: 0
      }
    },
    computed: {
      positionPopup(){
        let heightPopup = 100
        let offset = document.querySelectorAll('.v-dialog.v-dialog--active.v-dialog--fullscreen')[0].scrollTop
        return this.locationPopup + offset - heightPopup
      }
    },
    methods: {
      setLocationPopup(location){
        this.locationPopup = location
      }
    }
  }
  