<template>
    <v-row class="d-flex">
      <v-col :cols="tableWidth">
        <v-card class="pb-6 rounded-xl">
        <div class="d-flex pa-6" style="gap:50px">
          <div class="pt-2 search">
          <h2>{{ $t('variables.search') }}</h2>
          <div class="d-flex" style="gap:20px">
            <v-text-field
              label="Id"
              hide-details="auto"
              outlined
              v-model="search.id"
              @keyup.enter="searchMethod"
            ></v-text-field>
            <v-text-field
              :label="$t('variables.name')"
              hide-details="auto"
              outlined
              v-model="search.name"
              @keyup.enter="searchMethod"
            ></v-text-field>
            <div class="d-flex search-actions">
              <v-icon class="search-actions__action" :disable="searchWarning" @click="searchMethod">
                mdi-magnify
              </v-icon>
              <v-icon class="search-actions__action" @click="cleanSearchField">
                mdi-close
              </v-icon>
            </div>
          </div>
          <span v-if="searchWarning" style="color:#b53838;">{{ $t('variables.errorSearchSeveralParameters') }}</span>
        </div>
      </div>
    </v-card>

    <v-card class="mt-5 pb-6 rounded-xl pa-6">
      <v-btn
        fab
        dark
        small
        color="primary"
        @click="showCreate"
        class="btn-add"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>


      <h2>{{ $t('variables.variables') }}</h2>
      <v-data-table
        :headers="tableHeaders"
        :loading="!loading"
        :loading-text="$t('multilang.load')"
        :items="variables"
        item-key="id"
        density="compact"
        :items-per-page="tableItemsPerPage"
        :footer-props="tableFooterProps"
        class="mt-4"
        :sort-by="sortTableBy"
        :sort-desc="sortTableDesc"
        :custom-sort="customSort"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div class="table-item" @click="enterSelect(item.id)">
            {{ item.id }}
            <v-icon class="copy" @click="copyData(item.id)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.fieldName`]="{ item }">
          <div class="table-item" @click="enterSelect(item.id)">
            {{ item.fieldName }}
            <v-icon class="copy" @click="copyData(item.fieldName)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.personalData`]="{ item }">
          <div v-if="typeof item.personalData === 'boolean'" class="table-item" @click="enterSelect(item.id)">
            <v-icon>{{ dataStatus(item.personalData) }}</v-icon>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.localSave`]="{ item }">
          <div v-if="typeof item.localSave === 'boolean'" class="table-item" @click="enterSelect(item.id)">
            <v-icon>{{ dataStatus(item.localSave) }}</v-icon>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.fieldSettings`]="{ item }">
          <div v-if="item.fieldSettings && !(typeof item.fieldSettings === 'object' && !Object.keys(item.fieldSettings)?.length)" class="table-item" @click="enterSelect(item.id)">{{ item.fieldSettings }}
            <v-icon class="copy" @click="copyData(item.fieldSettings)">mdi-content-copy</v-icon>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
  <v-col cols="3" style="position: relative;" v-if="visibleEditor">
    <div class="editor-col pa-6 rounded-xl" :style="topPositionCol">
      <div style="position: fixed;">
        <v-btn
          class="btn-close"
          icon
          color="red"
          @click="closeEditor"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div v-if="newData?.id || visibleCopy || visibleAdd" class="pb-6 editor-col__content">
        <p v-if="newData.id" class="mr-6">{{ $t('variables.field') }}:
          <b style="color:#9c9c9c;">{{ newData.id }}</b>
          <v-icon class="copy_visible" @click="copyData(newData.id)">mdi-content-copy</v-icon>
        </p>
        <v-text-field
          :label="$t('variables.fieldName')"
          hide-details="auto"
          outlined
          v-model="newData.fieldName"
          require
        ></v-text-field>
        <div class="editor-col__content__checkbox">
          <v-checkbox
            v-model="newData.personalData"
            color="green"
            class="editor-col__content__checkbox-item"
          >
            <template v-slot:label>
              <div @click.stop="">
                {{ $t('variables.personalData') }}
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="newData.localSave"
            color="green"
            class="editor-col__content__checkbox-item"
          >
            <template v-slot:label>
              <div @click.stop="">
                {{ $t('variables.localSave') }}
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="json-editor">
          <JsonEditorVue
            v-model="newData.fieldSettings"
            v-bind="JsonEditorSetting"
            class="mb-3"
            style="position: relative;"
            ref="jsonEditor-fieldSettings"
          />
          <v-icon class="copy_json" @click="copyData(newData.fieldSettings)">mdi-content-copy</v-icon>
      </div>
        <div class="editor-col__btns">
          <template v-if="newData.id">
            <v-btn @click="updateMethod">{{ $t('update') }}</v-btn>
            <v-btn @click="duplicate(newData.id)">{{ $t('duplicate') }}</v-btn>
            <v-btn @click="deleteMethod(newData.id)">{{ $t('delete') }}</v-btn>
          </template>
          <template v-else>
            <v-btn @click="createMethod">{{ $t('create') }}</v-btn>
          </template>
        </div>
      </div>
      <SpinnerLoader 
        v-else
        color="blue" 
      />
    </div>
  </v-col>
</v-row>
</template>

<script>
import JsonEditorVue from 'json-editor-vue'
import Vue from 'vue'
import CRUD_UI_Mixin from "@/mixins/CRUD_UI_Mixin";

export default{

  data(){
    return {
      search: {
        id: '',
        name: '',
      },
      tableHeaders: [
        {text: 'Id', value: 'id'},
        {text: this.$t('variables.fieldName'), value: 'fieldName'},
        {text: this.$t('variables.personalData'), value: 'personalData'},
        {text: this.$t('variables.localSave'), value: 'localSave'},
        {text: this.$t('variables.fieldSettings'), value: 'fieldSettings'}
      ],      
      visibleEditor: false,
      visibleCopy: false,
      visibleAdd: false,      
      newData: {
        personalData: true,
        localSave: true
      },
      templateData: {
        personalData: true,
        localSave: true
      },
      searchWarning: false,
      JsonEditorSetting: {
        mode: 'text',
        statusBar: false,
        navigationBar: false,
      },
    }
  },
  components: { JsonEditorVue },
  mixins: [CRUD_UI_Mixin],
  methods: {
    dataStatus(data){
      if(data === null) return ''
      return data
        ? 'mdi-check'
        : 'mdi-close'
    },
    async actionShow(id){
      const data = await this.$store.dispatch('pageBuilder/getVariable', id)

      if(typeof data?.fieldSettings === "string" && data?.fieldSettings?.length) {
        data.fieldSettings = JSON.parse(data.fieldSettings)
      }    
      if(data?.fieldSettings === null || !Object.keys(data?.fieldSettings).length){
        delete data.fieldSettings
      }

      this.newData = data
    },

    duplicate(id){
      let {fieldName, personalData, localSave, fieldSettings} = this.variables.find(field => field.id === id)

      if(typeof fieldSettings === 'string' && fieldSettings.length) fieldSettings = JSON.parse(fieldSettings)

      this.visibleCopy = true
      this.newData = {fieldName, personalData, localSave, fieldSettings}
    },

    deleteMethod(id){
      this.$store.dispatch('pageBuilder/deleteVariable', {id, searchData: this.search})

      this.closeEditor()
    },

    searchMethod(){
      if(this.search.id && this.search.name) return
      if(!this.search.name && !this.search.id) return this.cleanSearchField()

      this.$store.dispatch('pageBuilder/loadData')
      this.$store.dispatch('pageBuilder/getVariablesFilter', this.search)
    },

    async createMethod(){
      console.log('this.newData', this.newData)
      const newVarId = await this.$store.dispatch('pageBuilder/createVariable', {newData: this.newData, searchData: this.search})

      this.enterSelect(newVarId)
    },

    updateMethod(){
      if(typeof this.newData.fieldSettings === "string" && this.newData.fieldSettings.length){
        this.newData.fieldSettings = JSON.parse(this.newData.fieldSettings)
      }

      this.$store.dispatch('pageBuilder/updateVariable', {varData: this.newData, searchData: this.search})
    },

    cleanSearchField(){
      this.search = { id: '', name: ''}
      this.$store.dispatch('pageBuilder/cleanSearchField')
    },

    loadData(){
      const variables = this.$store.getters['pageBuilder/variables']
      if(!variables?.length) this.$store.dispatch('pageBuilder/getVariables')    
    },
  },
  computed: {
    variables(){
      const variables = this.$store.getters['pageBuilder/variables']
      const searchedVariable = this.$store.getters['pageBuilder/searchedVariable']
      
      if(!this.loadedAllData) return []

      if(!searchedVariable?.length) return variables
      return searchedVariable
    },
    loading() {
      return this.variables && this.variables.length > 0 && this.loadedAllData
    },
    loadedAllData(){
      return this.$store.getters['pageBuilder/loadedAllData']
    }, 
  },

  watch: {
    search: {
      handler: function (value) {
        if(value.id && value.name) return this.searchWarning = true
        this.searchWarning = false
      },
      deep: true
    }
  },

  created(){
    this.loadData()
  },
}
</script>

<style lang="scss" scoped>
.copy{
  display: none;
  color: #7b66e8;
  position: absolute;
  top: 2px;
  right: 0px;
  scale: 0.7;

  &_visible{
    display: inline-flex !important;
    scale: 0.7;
    color: #7b66e8;
  }
  &_json{
    position: absolute;
    bottom: 65px;
    right: 30px;
    scale: 0.7;
    color: #7b66e8;
  }
}
.table-item{
  position: relative;
  width: 100%;
  padding-right: 10px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover .copy{
    display: inline-flex;
  }
  &__actions{
    cursor: pointer !important;
    font-size: 18px !important;
  }
}
.search{
  display: flex;
  flex-direction: column;
  gap: 20px !important;
  width: 100% !important;

  &-title{
    justify-content: space-between;
  }

  &-actions{
    align-items: center;

    &__action{
      width: 40px;
      height: 40px;
    }
  }
}
.editor-col{
  right: 1%;
  background-color: #ffffff;
  box-shadow: 0 0 32px 0 rgb(136 152 170 / 15%);
  width: 100%;
  max-width: 23%;
  position: fixed;
  overflow-y: scroll;
  display: flex;
  align-items: end;
  flex-direction: column;
  z-index: 20;

  &__btns{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__content{
    width: 100%;

    &__checkbox{
      display: flex;
      gap: 10px;

      &-item{
        width: 50%;
      }
    }
  }
}

.json-editor{
  position: relative;

  .copy_json{
    bottom: 10px;
    right: 10px;
  }
}

.btn{
  &-add{
    position: absolute; 
    top: 20px; 
    right: 20px;
  }
  &-close{
    position: absolute;
    top: -25px;
    right: -28px;
  }
}
</style>