<template>
  <v-row class="d-flex">
    <v-col :cols="tableWidth">
      <v-card class="pb-6 rounded-xl">
      <div class="d-flex pa-6" style="gap:50px">
        <div class="pt-2 search">

          <h2>{{ $t('variables.search') }}</h2>
          <div class="d-flex" style="gap:20px">
            <v-text-field
              label="Id"
              hide-details="auto"
              outlined
              v-model="search.id"
              @keyup.enter="searchRule"
            ></v-text-field>
            <v-text-field
              :label="$t('variables.name')"
              hide-details="auto"
              outlined
              v-model="search.name"
              @keyup.enter="searchRule"
            ></v-text-field>
            <div class="d-flex search-actions">
              <v-icon class="search-actions__action" :disable="searchWarning" @click="searchRule">
                mdi-magnify
              </v-icon>
              <v-icon class="search-actions__action" @click="cleanSearchField">
                mdi-close
              </v-icon>
            </div>
          </div>
          <span v-if="searchWarning" style="color:#b53838;">{{ $t('variables.errorSearchSeveralParameters') }}</span>
        </div>
      </div>
    </v-card>

    <v-card class="mt-5 pb-6 rounded-xl pa-6">
      <v-btn
        fab
        dark
        small
        color="primary"
        @click="showCreate"
        class="btn-add"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-data-table
        :headers="tableHeaders"
        :items="rules"
        :loading="!loading"
        :loading-text="$t('multilang.load')"
        item-key="id"
        density="compact"
        :items-per-page="tableItemsPerPage"
        :footer-props="tableFooterProps"
        class="mt-4"
        :sort-by="sortTableBy"
        :sort-desc="sortTableDesc"
        :custom-sort="customSort"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div class="table-item">
            <span @click="enterSelect(item.id)">{{ item.id }}</span>
            <v-icon class="copy" @click.prevent="copyData(item.id)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.ruleName`]="{ item }">
          <div class="table-item" @click="enterSelect(item.id)">
            {{ item.ruleName }}</div>
        </template>
        <template v-slot:[`item.method`]="{ item }">
          <div class="table-item" v-if="Object.keys(item.method).length">
            <pre @click="enterSelect(item.id)">{{ item.method }}</pre>
            <v-icon v-if="item.method?.id" class="copy" @click.prevent="copyData(item.method)">mdi-content-copy</v-icon>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.fieldName`]="{ item }">
          <div v-if="item.fieldName && !item.fieldName?.includes('null')" class="table-item">
            <span @click="enterSelect(item.id)">{{ item.fieldName }}</span>
            <v-icon v-if="item.fieldName" class="copy" @click.prevent="copyData(item.fieldName)">mdi-content-copy</v-icon>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.resultApiField`]="{ item }">
          <div v-if="item.resultApiField?.length" class="table-item"> 
            <span @click="enterSelect(item.id)">{{ item.resultApiField }}</span>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.operator`]="{ item }">
          <div v-if="item.operator?.length" class="table-item">
            <span @click="enterSelect(item.id)">{{ item.operator }}</span>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.answer`]="{ item }">
          <div v-if="item.answer?.length" class="table-item">
            <span @click="enterSelect(item.id)">{{ item.answer }}</span>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.isRequired`]="{ item }">
          <div v-if="item.isRequired?.length" class="table-item">
            <span @click="enterSelect(item.id)">{{ item.isRequired }}</span>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
  <v-col id="right-col" cols="3" style="position: relative;" v-if="visibleEditor">
    <div class="editor-col pa-6 rounded-xl" :style="topPositionCol">
      <div style="position: fixed;">
        <v-btn
          class="btn-close"
          icon
          color="red"
          @click="closeEditor"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div v-if="newData?.id || visibleAdd || visibleCopy" class="pb-6 mt-3 editor-col__content">
        <p v-if="newData.id" class="mr-6">{{ $t('variables.field') }}:
          <b style="color:#9c9c9c;">{{ newData.id }}</b>
          <v-icon class="copy_visible" @click="copyData(newData.id)">mdi-content-copy</v-icon>
        </p>
        <v-text-field
          :label="$t('rules.ruleName')"
          hide-details="auto"
          outlined
          v-model="newData.ruleName"
          require
        ></v-text-field>
        <v-checkbox
          v-model="newData.isRequired"
          color="green"
        >
          <template v-slot:label>
            <div @click.stop="">{{ $t('rules.isRequired') }}</div>
          </template>
        </v-checkbox>

        <Switcher
          :param1="$t('rules.method')"
          :param2="$t('rules.variable')"
          :selectedParam="typeOfRule"
          @switchParam="switchParam"
          :disabled="!!newData?.id"
        />
        <div v-if="typeOfRule === $t('rules.method')" class="d-flex align-center" style="gap:5px; position: relative;">
          <v-autocomplete
            :items="methods"
            v-model="newData.methodId"
            outlined
            item-value="id"
            :item-text="item => `${item.id}: ${item.methodName}`"
            :placeholder="$t('rules.selectMethod')"
            hide-details
            class="mt-1"
          />
          <v-btn
            v-if="selectedMethod"
            icon
            color="gray"
            @click="showMethod"
          >
            <v-icon class="method-help">mdi-help-circle-outline</v-icon>
          </v-btn>
        </div>
        <JsonEditorVue
          v-if="visibleMethod"
          v-model="selectedMethod"
          v-bind="JsonEditorSetting"
          class="mb-3"
          style="position: relative;"
        />
        <v-text-field
          v-if="typeOfRule === $t('rules.method')"
          :label="$t('rules.resultApiField')"
          hide-details="auto"
          outlined
          v-model="newData.resultApiField"
          require
        ></v-text-field>
        <v-autocomplete
          v-if="typeOfRule === $t('rules.variable')"
          :items="variables"
          v-model="newData.fieldName"
          outlined
          item-value="id"
          :item-text="item => `${item.id}: ${item.fieldName}`"
          :placeholder="$t('rules.selectFieldName')"
          hide-details
          class="mt-1"
        />
        <v-autocomplete
          :items="operators"
          v-model="newData.operator"
          outlined
          :placeholder="$t('rules.selectOperator')"
          hide-details
          class="mt-1"
        />
       <v-text-field
          :label="$t('rules.answer')"
          hide-details="auto"
          outlined
          v-model="newData.answer"
          require
        ></v-text-field>
        <!-- <v-text-field
          label="answerId"
          hide-details="auto"
          outlined
          v-model="newData.answerId"
          require
        ></v-text-field> -->
        <div class="editor-col__btns">
          <template v-if="newData.id">
            <v-btn @click="updateRule">{{ $t('update') }}</v-btn>
            <v-btn @click="duplicateRule(newData.id)">{{ $t('duplicate') }}</v-btn>
            <v-btn @click="deleteRule(newData.id)">{{ $t('delete') }}</v-btn>
          </template>
          <template v-else>
            <v-btn @click="createRule">{{ $t('create') }}</v-btn>
          </template>
        </div>
      </div>
      <SpinnerLoader 
        v-else
        color="blue" 
      />
    </div>
  </v-col>
</v-row>
</template>

<script>
import JsonEditorVue from 'json-editor-vue'
import Vue from 'vue'
import CRUD_UI_Mixin from "@/mixins/CRUD_UI_Mixin";
import Switcher from '@/components/UI/switcher.vue'

export default{
  data(){
    return {
      search: {
        id: '',
        name: ''
      },
      tableHeaders: [
        {text: 'Id', value: 'id'},
        {text: this.$t('rules.ruleName'), value: 'ruleName'},
        {text: this.$t('rules.method'), value: 'method'},
        {text: this.$t('rules.variable'), value: 'fieldName'},
        {text: this.$t('rules.resultApiField'), value: 'resultApiField'},
        {text: this.$t('rules.operator'), value: 'operator'},
        {text: this.$t('rules.answer'), value: 'answer'},
        {text: this.$t('rules.isRequired'), value: 'isRequired'},
        // {text: 'answerId', value: 'answerId'},
      ],
      visibleEditor: false,
      visibleCopy: false,
      visibleAdd: false,      
      visibleMethod: false,      
      newData: {},
      templateData: {
        isRequired: true
      },
      searchWarning: false,
      
      JsonEditorSetting: {
        mode: 'text',
        statusBar: false,
        navigationBar: false,
        mainMenuBar: false,
        readOnly: true,
      },
      operators: ['<', '<=', '>', '>=', '!=', '='],
      selectedMethod: null,
      typeOfRule: this.$t('rules.method'),
    }
  },
  components: { JsonEditorVue, Switcher },
  mixins: [CRUD_UI_Mixin],
  methods: {
    async actionShow(id){
      const data = await this.$store.dispatch('conditionsRules/getRuleById', {id, show: true})
      if(data?.fieldName) {
        const num = data.fieldName.match((/\d+/))
        if(num?.length) data.fieldName = Number(num[0])
        this.typeOfRule = this.$t('rules.variable')
      }

      if(data?.method?.id){
        data.methodId = data.method.id
        this.typeOfRule = this.$t('rules.method')
      }

      if(typeof data?.method === "string" && data?.method?.length){
        data.method = JSON.parse(data.method)
      }

      if(typeof data?.service === "string" && data?.service?.length){
        data.service = JSON.parse(data.service)
      }

      this.newData = data
    },

    duplicateRule(id){
      const {ruleName, method, resultApiField, answerId, operator, isRequired, fieldName,
          conditionType, service, answer} = this.newData

      this.visibleCopy = true
      this.newData = {ruleName, method, resultApiField, answerId, operator, isRequired, fieldName,
      conditionType, service, answer, methodId: method.id}
    },

    deleteRule(id){
      this.$store.dispatch('conditionsRules/deleteRule', {id, searchData: this.search})

      this.closeEditor()
    },

    searchRule(){
      if(this.search.id && this.search.name) return
      if(!this.search.id && !this.search.name) return this.cleanSearchField()

      this.$store.dispatch('conditionsRules/loadData')
      if(this.search.id) this.$store.dispatch('conditionsRules/getRuleById', {id: this.search.id})
      else this.$store.dispatch('conditionsRules/getRulesFiltered', this.search.name)
    },

    async createRule(){
      if(this.typeOfRule === this.$t('rules.variable')) this.newData.fieldName = `{{field.${this.newData.fieldName}}}`

      const newId = await this.$store.dispatch('conditionsRules/createRule', {newData: this.newData, searchData: this.search})

      this.enterSelect(newId)
      this.visibleCopy = false
    },

    updateRule(){
      if(this.typeOfRule === this.$t('rules.variable')) this.newData.fieldName = `{{field.${this.newData.fieldName}}}`;

      if ("methodId" in this.newData) {
        this.newData.method.id = this.newData.methodId;
        this.newData.method.value = this.methods.find(method => method.id === this.newData.methodId).methodName;
      }

      this.$store.dispatch('conditionsRules/updateRule', {data: this.newData, searchData: this.search})
    },

    cleanSearchField(){
      this.search = { id: '', name: ''}
      this.$store.dispatch('conditionsRules/cleanSearchedRules')
    },
    
    async getMethod(id){
      const res = await this.$store.dispatch('externalSources/getMethodById', {id})
      this.selectedMethod = res?.responseJson
    },

    showMethod(){
      this.visibleMethod = !this.visibleMethod
    },

    switchParam(param){
      if(this.typeOfRule === param) return
      
      this.visibleMethod = false
      this.typeOfRule = param

      if(param.toLowerCase() === 'method') delete this.newData.fieldName
      else {
        delete this.newData.method
        delete this.newData.methodId
        delete this.newData.resultApiField
        this.selectedMethod = null
      }
    },
    loadData(){
      const variables = this.$store.getters['pageBuilder/variables']
      const rules = this.$store.getters['conditionsRules/rules']
      const methods = this.$store.getters['externalSources/methods']

      if(!variables?.length) this.$store.dispatch('pageBuilder/getVariables')    
      if(!rules?.length) this.$store.dispatch('conditionsRules/getRules')    
      if(!methods?.length) this.$store.dispatch('externalSources/getMethods')    
    },
  },
  computed: {
    rules(){
      const rules = this.$store.getters['conditionsRules/rules']
      const searchedRules = this.$store.getters['conditionsRules/searchedRules']

      if(!this.loadedAllData) return []

      if(!searchedRules?.length) return rules
      return searchedRules
    },

    loading(){
      return this.rules && this.rules.length > 0 && this.loadedAllData
    },

    loadedAllData(){
      return this.$store.getters['conditionsRules/loadedAllData']
    }, 

    variables(){
      return this.$store.getters['pageBuilder/variables']
    },

    methods(){
      return this.$store.getters['externalSources/externalSources']
    }
  },

  watch: {
    search: {
      handler: function (value) {
        if(value.id && value.name) return this.searchWarning = true
        this.searchWarning = false
      },
      deep: true
    },
    'newData.method': {
      handler: function (data) {
        const id = data?.id ? data.id : data
    
        /* eslint-disable */ 
        if(typeof id === 'number') this.getMethod(id)
      },
      deep: true
    },
    'newData.methodId': {
      handler: function (id) {
        if(typeof id === 'number') this.getMethod(id)
      },
      deep: true
    },
  },
  created(){
    this.loadData()
  },
}
</script>

<style lang="scss" scoped>
.copy{
  display: none;
  color: #7b66e8;
  position: absolute;
  top: 2px;
  right: 0px;
  scale: 0.7;
  z-index: 10;

  &_visible{
    display: inline-flex !important;
    scale: 0.7;
    color: #7b66e8;
  }

  &_json{
    position: absolute;
    bottom: 65px;
    right: 30px;
    scale: 0.7;
    color: #7b66e8;
  }

}
.table-item{
  position: relative;
  width: 100%;
  padding-right: 10px;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  height: 100%;

  &:hover .copy{ 
    display: inline-flex;
  }
  &__actions{
    cursor: pointer !important;
    font-size: 18px !important;
  }
}
.search{
  display: flex;
  flex-direction: column;
  gap: 20px !important;
  width: 100% !important;

  &-title{
    justify-content: space-between;
  }

  &-actions{
    align-items: center;

    &__action{
      width: 40px;
      height: 40px;
    }
  }
}
.editor-col{
  right: 1%;
  background-color: #ffffff;
  box-shadow: 0 0 32px 0 rgb(136 152 170 / 15%);
  width: 100%;
  max-width: 23%;
  position: fixed;
  overflow-y: scroll;
  display: flex;
  align-items: end;
  flex-direction: column;
  z-index: 20;

  &__btns{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__content{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.json-editor{
  position: relative;

  .copy_json{
    bottom: 10px;
    right: 10px;
  }
}
.btn{
  &-add{
    position: absolute; 
    top: 20px; 
    right: 20px;
  }
  &-close{
    position: absolute;
    top: -25px;
    right: -28px;

    &-method{
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
.method-selected {
	position: absolute;
	top: 0;
  left: -20px;
  background: #dedede;
  z-index: 9;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
}
</style>