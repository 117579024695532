<template>
  <v-row class="d-flex">
    <v-col :cols="tableWidth">
      <v-card class="pb-6 rounded-xl">
      <div class="d-flex pa-6" style="gap:50px">
        <div class="pt-2 search">

          <h2>{{ $t('variables.search') }}</h2>
          <div class="d-flex" style="gap:20px">
            <v-text-field
              label="Id"
              hide-details="auto"
              outlined
              v-model="search.id"
              @keyup.enter="searchMethod"
            ></v-text-field>
            <v-text-field
              :label="$t('variables.name')"
              hide-details="auto"
              outlined
              v-model="search.name"
              @keyup.enter="searchMethod"
            ></v-text-field>
            <v-text-field
              label="Url"
              hide-details="auto"
              outlined
              v-model="search.url"
              @keyup.enter="searchMethod"
            ></v-text-field>
            <div class="d-flex search-actions">
              <v-icon class="search-actions__action" :disable="searchWarning" @click="searchMethod">
                mdi-magnify
              </v-icon>
              <v-icon class="search-actions__action" @click="cleanSearchField">
                mdi-close
              </v-icon>
            </div>
          </div>
          <span v-if="searchWarning" style="color:#b53838;">{{ $t('variables.errorSearchSeveralParameters') }}</span>
        </div>
      </div>
    </v-card>

    <v-card class="mt-5 pb-6 rounded-xl pa-6">
      <v-btn
        fab
        dark
        small
        color="primary"
        @click="showCreate"
        class="btn-add"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-data-table
        :headers="tableHeaders"
        :items="externalSources"
        :loading="!loading"
        :loading-text="$t('multilang.load')"
        item-key="id"
        density="compact"
        :items-per-page="tableItemsPerPage"
        :footer-props="tableFooterProps"
        class="mt-4"
        :sort-by="sortTableBy"
        :sort-desc="sortTableDesc"
        :custom-sort="customSort"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div class="table-item" @click="enterSelect(item.id)">{{ item.id }}
            <v-icon class="copy" @click="copyData(item.id)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.methodName`]="{ item }">
          <div class="table-item" @click="enterSelect(item.id)">{{ item.methodName }}
            <v-icon class="copy" @click="copyData(item.methodName)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.methodUrl`]="{ item }">
          <div class="table-item" @click="enterSelect(item.id)">{{ item.methodUrl }}
            <v-icon class="copy" @click="copyData(item.methodUrl)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.apiId`]="{ item }">
          <div class="table-item" @click="enterSelect(item.id)">{{ apis.find(type => type.id === item.apiId)?.apiName }}
            <v-icon class="copy" @click="copyData(item.apiId)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.typeId`]="{ item }">
          <div class="table-item" @click="enterSelect(item.id)">{{ types.find(type => type.id === item.typeId)?.method }}
            <v-icon class="copy" @click="copyData(item.typeId)">mdi-content-copy</v-icon>
          </div>
        </template>
        <!-- <template v-slot:[`item.requestJson`]="{ item }">
          <div class="table-item" @click="enterSelect(item.id)">{{ item.requestJson }}
            <v-icon class="copy" @click="copyData(item.requestJson)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.responseJson`]="{ item }">
          <div class="table-item" @click="enterSelect(item.id)">{{ item.responseJson }}
            <v-icon class="copy" @click="copyData(item.responseJson)">mdi-content-copy</v-icon>
          </div>
        </template> -->
      </v-data-table>
    </v-card>
  </v-col>
  <v-col id="right-col" cols="3" style="position: relative;" v-if="visibleEditor">
    <div class="editor-col pa-6 rounded-xl" :style="topPositionCol">
      <div style="position: fixed;">
        <v-btn
          class="btn-close"
          icon
          color="red"
          @click="closeEditor"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div v-if="newData?.id || visibleAdd || visibleCopy" class="pb-6 mt-3">
        <p v-if="newData.id" class="mr-6">{{ $t('variables.field') }}:
          <b style="color:#9c9c9c;">{{ newData.id }}</b>
          <v-icon class="copy_visible" @click="copyData(newData.id)">mdi-content-copy</v-icon>
        </p>
        <v-text-field
          :label="$t('externalSource.methodName')"
          hide-details="auto"
          outlined
          v-model="newData.methodName"
          require
        ></v-text-field>
        <v-text-field
          :label="$t('externalSource.methodUrl')"
          hide-details="auto"
          outlined
          v-model="newData.methodUrl"
          require
        ></v-text-field>
        <v-autocomplete
          :items="apis"
          v-model="newData.apiId"
          item-value="id"
          item-text="apiName"
          outlined
          :placeholder="$t('externalSource.selectApiId')"
          hide-details
          class="mt-1"
        />
        <v-autocomplete
          :items="types"
          v-model="newData.typeId"
          item-value="id"
          item-text="method"
          outlined
          :placeholder="$t('externalSource.selectTypeId')"
          hide-details
          class="mt-1"
        />
        <div>
          <div class="json-editor">
            <span>{{ $t('externalSource.request') }}</span>
            <JsonEditorVue
              v-model="newData.requestJson"
              v-bind="JsonEditorSetting"
              class="mb-3"
              style="position: relative;"
              ref="jsonEditor-request"
            />
            <v-icon class="copy_json" @click="copyData(newData.requestJson)">mdi-content-copy</v-icon>
          </div>
          <div class="json-editor">
            <span>{{ $t('externalSource.response') }}</span>
            <JsonEditorVue
              v-model="newData.responseJson"
              v-bind="JsonEditorSetting"
              class="mb-3"
              style="position: relative;"
              ref="jsonEditor-response"
            />
            <v-icon class="copy_json" @click="copyData(newData.responseJson)">mdi-content-copy</v-icon>
          </div>
      </div>
        <div class="editor-col__btns">
          <template v-if="newData.id">
            <v-btn :disabled="requireFields" @click="updateMethod">{{ $t('update') }}</v-btn>
            <v-btn @click="duplicate(newData.id)">{{ $t('duplicate') }}</v-btn>
            <v-btn @click="deleteMethod(newData.id)">{{ $t('delete') }}</v-btn>
          </template>
          <template v-else>
            <v-btn :disabled="requireFields" @click="createMethod">{{ $t('create') }}</v-btn>
          </template>
        </div>
      </div>
      <SpinnerLoader 
        v-else
        color="blue" 
      />
    </div>
  </v-col>
</v-row>
</template>

<script>
import JsonEditorVue from 'json-editor-vue'
import Vue from 'vue'
import CRUD_UI_Mixin from "@/mixins/CRUD_UI_Mixin";

export default{
  data(){
    return {
      search: {
        id: '',
        name: '',
        url: ''
      },
      tableHeaders: [
        {text: 'Id', value: 'id'},
        {text: this.$t('externalSource.methodName'), value: 'methodName'},
        {text: this.$t('externalSource.methodUrl'), value: 'methodUrl'},
        {text: this.$t('externalSource.apiId'), value: 'apiId'},
        {text: this.$t('externalSource.typeId'), value: 'typeId'},
        // {text: 'Request json', value: 'requestJson', sortable: false},
        // {text: 'Response json', value: 'responseJson', sortable: false},
      ],
      visibleEditor: false,
      visibleCopy: false,
      visibleAdd: false,      
      newData: {},
      templateData: {
        requestJson: {
          body: {},
          cookies: {},
          headers: {}
        },
        responseJson: {
          body: {},
          status: "",
          cookies: {},
          headers: {}
        }
      },
      searchWarning: false,
      
      JsonEditorSetting: {
        mode: 'text',
        statusBar: false,
        navigationBar: false,
      },
    }
  },
  components: { JsonEditorVue },
  mixins: [CRUD_UI_Mixin],
  methods: {
    async actionShow(id){
      const data = await this.$store.dispatch('externalSources/getMethodById', {id, show: true})
   
      if(typeof data?.requestJson === "string" && data?.requestJson?.length){
        data.requestJson = JSON.parse(data.requestJson)
      }
      if(typeof data?.responseJson === "string" && data?.responseJson?.length){
        data.responseJson = JSON.parse(data.responseJson)
      }

      if(data?.requestJson === null){
        data.requestJson = JSON.parse(JSON.stringify(this.templateData.requestJson))
      }
      if(data?.responseJson === null){
        data.responseJson = JSON.parse(JSON.stringify(this.templateData.responseJson))
      }

      this.newData = data
    },

    duplicate(id){
      let {apiId, methodName, methodUrl, requestJson, responseJson, responseTypeId, timeoutConnect, 
        timeoutReceive, typeId} = this.externalSources.find(field => field.id === id)

      if(typeof requestJson === 'string' && requestJson.length) requestJson = JSON.parse(requestJson)
      if(typeof responseJson === 'string' && responseJson.length) responseJson = JSON.parse(responseJson)

      this.visibleCopy = true
      this.newData = {apiId, methodName, methodUrl, requestJson, responseJson, responseTypeId,
        timeoutConnect, timeoutReceive, typeId}
    },

    deleteMethod(id){
      this.$store.dispatch('externalSources/deleteMethod', {id, searchData: this.search})

      this.closeEditor()
    },

    searchMethod(){
      if((this.search.id && this.search.name) || (this.search.id && this.search.url) || (this.search.name && this.search.url)) return
      if(!this.search.name && !this.search.url && !this.search.id) return this.cleanSearchField()

      this.$store.dispatch('externalSources/loadData')
      if(this.search.id) this.$store.dispatch('externalSources/getMethodById', {id: this.search.id})
      else this.$store.dispatch('externalSources/getMethodsFiltered', this.search)
    },

    async createMethod(){
      const newId = await this.$store.dispatch('externalSources/createMethod', {newData: this.newData, searchData: this.search})

      this.enterSelect(newId)
    },

    updateMethod(){
      if(typeof this.newData.requestJson === "string" && this.newData.requestJson.length){
        this.newData.requestJson = JSON.parse(this.newData.requestJson)
      }
      if(typeof this.newData.responseJson === "string" && this.newData.responseJson.length){
        this.newData.responseJson = JSON.parse(this.newData.responseJson)
      }

      this.$store.dispatch('externalSources/updateMethod', {data: this.newData, searchData: this.search})
    },

    cleanSearchField(){
      this.search = { id: '', name: '', url: ''}
      this.$store.dispatch('externalSources/cleanSearchedMethods')
    },

    loadData(){
      const methods = this.$store.getters['externalSources/methods']
      const types = this.$store.getters['externalSources/types']
      const apis = this.$store.getters['externalSources/apis']

      if(!methods?.length) this.$store.dispatch('externalSources/getMethods')    
      if(!types?.length) this.$store.dispatch('externalSources/getMethodTypes')    
      if(!apis?.length) this.$store.dispatch('externalSources/getMethodApis')    
    },
  },
  computed: {
    externalSources(){
      const sources = this.$store.getters['externalSources/externalSources']
      const searchedSources = this.$store.getters['externalSources/searchedExternalSources']

      if(!this.loadedAllData) return []

      if(!searchedSources?.length) return sources
      return searchedSources
    },
    loading(){
      return this.externalSources && this.externalSources.length > 0 && this.loadedAllData
    },
    loadedAllData(){
      return this.$store.getters['externalSources/loadedAllData']
    }, 
    types(){
      return this.$store.getters['externalSources/types']
    },
    apis(){
      return this.$store.getters['externalSources/apis']
    },
    requireFields(){
      if(!this.newData.methodName || !this.newData.methodUrl || !this.newData.apiId || !this.newData.typeId) return true
      return false
    }
  },

  watch: {
    search: {
      handler: function (value) {
        if((value.id && value.name) || (value.id && value.url) || (value.name && value.url)) return this.searchWarning = true
        this.searchWarning = false
      },
      deep: true
    }
  },

  created(){
    this.loadData()   
  },
}
</script>

<style lang="scss" scoped>
.copy{
  display: none;
  color: #7b66e8;
  position: absolute;
  top: 2px;
  right: 0px;
  scale: 0.7;

  &_visible{
    display: inline-flex !important;
    scale: 0.7;
    color: #7b66e8;
  }

  &_json{
    position: absolute;
    bottom: 65px;
    right: 30px;
    scale: 0.7;
    color: #7b66e8;
  }

  // &-inline{
  //   top: 5px;
  //   right: 2px;
  // }
}
.table-item{
  position: relative;
  width: 100%;
  padding-right: 10px;
  cursor: pointer !important;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover .copy{
    display: inline-flex;
  }
  &__actions{
    cursor: pointer !important;
    font-size: 18px !important;
  }
}
.search{
  display: flex;
  flex-direction: column;
  gap: 20px !important;
  width: 100% !important;

  &-title{
    justify-content: space-between;
  }

  &-actions{
    align-items: center;

    &__action{
      width: 40px;
      height: 40px;
    }
  }
}
.editor-col{
  right: 1%;
  background-color: #ffffff;
  box-shadow: 0 0 32px 0 rgb(136 152 170 / 15%);
  width: 100%;
  max-width: 23%;
  position: fixed;
  overflow-y: scroll;
  display: flex;
  align-items: end;
  flex-direction: column;
  z-index: 20;

  &__btns{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  >div{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.json-editor{
  position: relative;

  .copy_json{
    bottom: 10px;
    right: 10px;
  }
}
.btn{
  &-add{
    position: absolute; 
    top: 20px; 
    right: 20px;
  }
  &-close{
    position: absolute;
    top: -25px;
    right: -28px;
  }
}
</style>