import {pageBuilderService} from "@/services/pageBuilder.service";
import Vue from "vue";
import i18n from "@/plugins/i18n.js";
import {requestWithOffset} from '@/utils/utils-functions'


export const pageBuilder = {
    namespaced: true,
    state: {
      screen: [],
      node: [],
      field: [],
      widget: [],
      group: [],
      preset: [],
      process: [],
      function: [],
      collection: [],
      wrapper: [],
      currentList: [],
      TYPE_REFS: {},
        currentNode: null,
        variables: [],
        searchedVariable: [],
        loadedAllData: false,
    },
    getters: {
      screen: state => state.screen,
      node: state => state.nodes,
      field: state => state.field,
      widget: state => state.widget,
      group: state => state.group,
      preset: state => state.preset,
      process: state => state.process,
      function: state => state.function,
      collection: state => state.collection,
      wrapper: state => state.wrapper,
      types: state => state.TYPE_REFS,
      currentList: state => state.currentList,
        currentNode: state => state.currentNode,
        variables: state => state.variables,
        searchedVariable: state => state.searchedVariable,
        loadedAllData: state => state.loadedAllData
    },
    mutations: {
      getElementsByType(state, response) {
          state[state.TYPE_REFS.find(type => type.id === response.id).prefixName] = response.data;
          state.currentList = response.data;
      },
      getTypesDictionary(state, value) {
          state.TYPE_REFS = value;
      },
        getNode(state, node) {
            state.currentNode = node
        },
        getVariables(state, variables) {
            if(variables?.fieldNames) state.variables.push(...variables.fieldNames)
            else state.variables = variables.fieldNames
        },
        getSearchedVariable(state, variables) { 
            if(variables?.fieldNames) state.searchedVariable = variables.fieldNames
            else state.searchedVariable = [variables]
        },
        deleteVariable(state, {id, searchData}){
            state.variables = state.variables.filter(field => field.id !== id)
            if(searchData?.name || searchData?.id) state.searchedVariable = state.searchedVariable.filter(field => field.id !== id)
        },
        addVariable(state, {dataVariable, searchData}){
            state.variables.push(dataVariable)
            if(searchData?.name || searchData?.id) state.searchedVariable.push(dataVariable)
        },
        cleanSearchField(state){
            state.searchedVariable = null
        },
        cleanVariables(state){
            state.variables = []
        },
        setLoaded(state, loaded){
            state.loadedAllData = loaded
        }
    },
    actions: {
      async getTypesDictionary({commit}) {
        const response = await pageBuilderService.getTypesDictionary();
        commit('getTypesDictionary', response.response);
      },
      async getElementsByType({commit}, nodeType) {
          const response = await pageBuilderService.getElementsByType(nodeType);
          commit('getElementsByType', {id: nodeType, data: response.response});
      },
      
      async getNodeProperties({commit}, payload) {
        return await pageBuilderService.getNodeProperties(payload);
      },
        async getNode({commit}, db_id) {
            const response = await pageBuilderService.getNode(db_id);
            commit('getNode', response.response);

        },
        async getVariables({commit, dispatch, getters}, searchData){
            const response = await pageBuilderService.getVariables({offset: searchData?.offset || 0})

            if(getters.variables.length > response.response.total) commit('cleanVariables')
            commit('getVariables', response.response);

            const loaded = requestWithOffset(response.response, searchData, 'getVariables', dispatch)
            if(loaded) commit('setLoaded', loaded)
        },

        async getVariablesFilter({commit, dispatch}, searchData){
            const response = await pageBuilderService.getVariables({...searchData, offset: searchData?.offset || 0})
            console.log("🚀 ~ getVariablesFilter response:", response)
            
            if(!response || !response.response?.fieldNames?.length && !response.response?.id) {
                dispatch('alert/error', i18n.t('variables.alert.errorFindVariable'), { root: true })
                return commit('setLoaded', true)
            }
            commit('getSearchedVariable', response.response);

            if(!response.response?.id){
                const loaded = requestWithOffset(response.response, searchData, 'getVariablesFilter', dispatch)
                if(loaded) commit('setLoaded', loaded)
            } else {
                commit('setLoaded', true)
            }
        },

        loadData({commit}){
            commit('setLoaded', false)
        },

        cleanSearchField({commit}){
            commit('cleanSearchField')
        },

        async createVariable({commit, dispatch}, {newData, searchData}){
            const response = await pageBuilderService.createVariable(newData)

            if(!response.response || response.status === 0) {
                dispatch('alert/error', i18n.t('variables.alert.createError'), { root: true })
            }else{
                dispatch('alert/success', i18n.t('variables.alert.createSuccess'), { root: true })

                const createdVar = await pageBuilderService.getVariables({id: response.response.id})
                commit('addVariable', {dataVariable: createdVar.response, searchData})

                return response.response.id
            }
        },
        async getVariable({commit}, id){

            const response = await pageBuilderService.getVariables({id});

            commit('setLoaded', true)
            if(!response.response || response.status === 0){
                dispatch('alert/error', i18n.t('variables.alert.errorFindVariable'), { root: true })
            }else{
                return response.response
            }
        },
        async updateVariable({commit, dispatch}, {varData, searchData}){
            const response = await pageBuilderService.updateVariable(varData)


            if(!response.response || response.status === 0){
                dispatch('alert/error', i18n.t('variables.alert.updateError'), { root: true })
            }else{
                dispatch('alert/success', i18n.t('variables.alert.updateSuccess'), { root: true })

                commit('deleteVariable', {id: varData.id, searchData})
                commit('addVariable', {dataVariable: varData, searchData})
            }
        },
        async deleteVariable({commit, dispatch}, {id, searchData}){
            const response = await pageBuilderService.deleteVariable(id)

            if(!response.statusCode || response.statusCode !== 204){
                dispatch('alert/error', i18n.t('variables.alert.deleteError'), { root: true })
            }else{
                dispatch('alert/success', i18n.t('variables.alert.deleteSuccess'), { root: true })

                if(searchData?.id) return dispatch('cleanSearchField')
                commit('deleteVariable', {id, searchData})
            }
        }
    }
}
