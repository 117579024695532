import { defaultApiInstance } from "@/api";

export const pageBuilderService = {
    getElementsByType,
    getNode,
    createNode,
    changeNode,
    getTypesDictionary,
    getVariables,
    createVariable,
    updateVariable,
    deleteVariable,
    getNodeProperties,
    getFieldProps
}

/**
 * @param {Number} nodeTypeId - type id for by which we can get elements for this type
 * */
async function getElementsByType(nodeTypeId) {
    return defaultApiInstance.get(`/editor/nodes?nodeType=${nodeTypeId}`);
}

async function getNode(nodeId) {
    return defaultApiInstance.get(`/editor/nodes/${nodeId}`);
}

async function getNodeProperties(nodeId) {
    return defaultApiInstance.get(`/properties?idNode=${nodeId}`);
}

async function changeNode(partId, nodePart) {
    return defaultApiInstance.patch(`/editor/nodes/${partId}`, nodePart);
}

async function getTypesDictionary() {
    return defaultApiInstance.get('/editor/nodes/types');
}

async function createNode(payload) {
    return defaultApiInstance.post('/editor/nodes', payload);
}

async function getVariables(searchData) {
    let query = `?q=&offset=${searchData.offset}`
    if(searchData?.id) query = '/' + searchData.id
    if(searchData?.name) query = `?q=${searchData.name}&offset=${searchData.offset}`

    return defaultApiInstance.get(`/editor/nodes/fieldName${query}`);
}

async function createVariable(varData) {
    return defaultApiInstance.post('/editor/nodes/fieldName', varData);
}

async function updateVariable(updateVariable) {
    return defaultApiInstance.patch(`/editor/nodes/fieldName/${updateVariable.id}`, updateVariable);
}

async function deleteVariable(id) {
    return defaultApiInstance.delete(`/editor/nodes/fieldName/${id}`);
}

async function getFieldProps() {
    return defaultApiInstance.get('/editor/nodes?nodeType=3');
}

export const unHighlightBuilderServices = [{
    method: 'post',
    url: '/editor/nodes/fieldName'
},{
    method: 'patch',
    url: '/editor/nodes/fieldName'
},{
    method: 'delete',
    url: '/editor/nodes/fieldName'
},{
    method: 'get',
    url: '/editor/nodes/fieldName'
}]