import { render, staticRenderFns } from "./GeneralNodeSettings.vue?vue&type=template&id=ec3db5ce&scoped=true&"
import script from "./GeneralNodeSettings.vue?vue&type=script&lang=js&"
export * from "./GeneralNodeSettings.vue?vue&type=script&lang=js&"
import style0 from "./GeneralNodeSettings.vue?vue&type=style&index=0&id=ec3db5ce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec3db5ce",
  null
  
)

export default component.exports